import React, {useEffect, useLayoutEffect, useRef} from 'react';

const Page = ({children, className}) => {

    return (
        <div className={`flex flex-col a4 overflow-hidden ${className}`}>
            {children}
        </div>
    );
};

export default Page;