import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../components/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_SITES} from "../api/endpoints";
import {toast} from "react-toastify";
import SiteCard from "../components/SiteCard";
import { IoIosSearch } from "react-icons/io";
import siteAdd from "../static/images/sites/site-add.svg";
import { MdClose } from "react-icons/md";
import FieldInput from "../components/FieldInput";
import {useParams} from "react-router-dom";
import SitesSkeleton from "../components/skeletons/SitesSkeleton";


const Sites = () => {
    const { setNavbarData } = useContext(AppContext);
    const params = useParams();
    const axios = useAxiosPrivate()

    const [sites, setSites] = useState([])
    const [filteredSites, setFilteredSites] = useState(sites);
    const [search, setSearch] = useState("");

    const [isConnectedOnly, setIsConnectedOnly] = useState(false);
    const [siteUrl, setSiteUrl] = useState("");
    const [siteName, setSiteName] = useState("");

    useEffect(() => {
        setNavbarData({name:'Sites'})
    }, [setNavbarData]);

    const fetchSites = async () => {
        try {
            const response = await axios.get(API_SITES(params?.orgId))
            setSites(response?.data)
        }catch(err){
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch reports failed")
            }
        }
    }

    useEffect(() => {
        fetchSites()
    }, [])

    useEffect(() => {
        const searchLower = search.toLowerCase();
        const filtered = sites.filter( (site) => {
            const matchesSearch =
                site.name.toLowerCase().includes(searchLower) ||
                site.url.toLowerCase().includes(searchLower);
            const matchesConnection = !isConnectedOnly || site.is_connected;

            return matchesSearch && matchesConnection;
        });

        setFilteredSites(filtered);
    }, [search, sites, isConnectedOnly]);

    const addSiteHandler = async () => {
        const toast_id = toast.loading("Adding new site please wait...")
        try{
            const response = await axios.post(API_SITES(params?.orgId), {
                name: siteName,
                url: siteUrl,
            })
            setSites(prevState => [
                ...prevState,
                response?.data
            ])
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
            document.getElementById('add-site-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 5000})
            }else {
                toast.update(toast_id, {render: "Adding site failed", type: "error", isLoading: false, autoClose: 2000})
            }
        }
    }

    return (
        <section>
            <div className='flex items-center rounded-lg justify-between'>
                <div className='flex'>
                    <input onChange={(e) => setSearch(
                        e.target.value)} value={search} type="text"
                           placeholder='Search for sites'
                           className='text-sm outline-none focus:outline-none focus:ring-0 focus:border-guinea bg-transparent border border-gray-600 rounded-lg w-[400px] px-4 py-2 text-ultimate'/>
                    <IoIosSearch className='w-7 h-7 -ml-9 mt-1 cursor-pointer text-ultimate'/>
                    <label className="inline-flex items-center cursor-pointer ml-10">
                        <input type="checkbox" onChange={() => setIsConnectedOnly(!isConnectedOnly)}
                               value={`${isConnectedOnly}`} checked={isConnectedOnly} className="sr-only peer"/>
                        <div
                            className="relative w-9 h-5 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-water"></div>
                        <span className={`ms-2 text-[12px] font-medium text-${isConnectedOnly ? "water" : "ultimate"}`}>Connected only</span>
                    </label>
                </div>

                <div className='flex gap-4 items-center justify-center'>
                    <div onClick={() => document.getElementById('add-site-modal').showModal()}
                         className='group flex flex-col justify-center items-center cursor-pointer'>
                        <img className='w-9 h-9 transition duration-300 group-hover:scale-[105%] mt-[5px]' src={siteAdd}
                             alt='site-add'/>
                        <span className='text-sm text-guinea group-hover:text-guinea-light'>Add New Site</span>
                    </div>

                </div>
            </div>
            <div className='flex gap-8 flex-wrap mt-8'>
                {sites && sites.length > 0 ? (
                    filteredSites.map((site) => (
                    <SiteCard key={site.id} data={site}/>
                ))): <SitesSkeleton count={4}/>}
            </div>

            <dialog id="add-site-modal" className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                    <h3 className="font-bold text-lg text-guinea font-oswald">Add New Site</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setSiteUrl} value={siteUrl} type='text' id='site-url' title='Site URL'/>
                        <FieldInput callback={setSiteName} value={siteName} type='text' id='site-name' title='Site Name'/>
                        <button onClick={addSiteHandler} className="mt-4 flex justify-center py-2 items-center gap-x-2 text-water transition duration-200 text-sm rounded w-full hover:text-water-light border border-water hover:border-water-light font-istok">
                            <span>Add Site</span></button>
                    </div>

                    <div className='text-toolbar-size text-guinea mt-8'>
                        Grand Digital Connect plugin should be installed and active to be able to add new site.
                        If site is not connected try to disable the plugin and reactivate again.
                    </div>
                </div>
            </dialog>

        </section>
    );
};

export default Sites;