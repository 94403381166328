import React, {useEffect} from 'react';
import WpPdfPlugin from "../../../../components/WpPdfPlugin";

const PluginTable = (props) => {

    const [plugins, setPlugins] = React.useState(props?.plugins);

    return (
        <table className="table">
            <thead>
            <tr>
                <th className='text-[10px] border-b border-b-gray-700 text-guinea uppercase'>Name</th>
                <th className='text-[10px] border-b border-b-gray-700 text-guinea uppercase'>Current Version</th>
                <th className='text-[10px] border-b border-b-gray-700 text-guinea uppercase'>Last Version</th>
                <th className='text-[10px] border-b border-b-gray-700 text-guinea uppercase'>Cvss Score</th>
                <th className='text-[10px] border-b border-b-gray-700 text-guinea uppercase'>Severity</th>
            </tr>
            </thead>
            <tbody>
            {plugins && plugins.length > 0 && (
                plugins.map((plugin, index) => (
                    <WpPdfPlugin
                        key={index}
                        name={plugin?.name}
                        currentVersion={plugin?.current_version}
                        lastVersion={plugin?.available_version}
                        cvssScore={plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.cvss_score : " "}
                        severity={plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.severity : " "}
                        active={plugin?.active}
                        pluginSlug={plugin?.slug}
                    />
                ))
            )}
            </tbody>
            <tfoot>
                <tr>
                    <th className='text-[10px] text-guinea uppercase'>Name</th>
                    <th className='text-[10px] text-guinea uppercase'>Current Version</th>
                    <th className='text-[10px] text-guinea uppercase'>Last Version</th>
                    <th className='text-[10px] text-guinea uppercase'>Cvss Score</th>
                    <th className='text-[10px] text-guinea uppercase'>Severity</th>
                </tr>
            </tfoot>
        </table>
    );
};

export default PluginTable;